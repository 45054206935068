/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import Offers from "../../components/md/Offers";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    a: "a",
    hr: "hr",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "¿Sabes lo que es un VPN? Te lo resumo en ", React.createElement(_components.strong, null, "3 frases"), ":"), "\n", React.createElement("div", {
    class: "box"
  }, React.createElement("p", null, React.createElement(_components.p, null, "En Internet, cualquier página puede ", React.createElement("strong", null, "rastrearte mediante tu IP"), ".\nEs como tu número de identidad.")), React.createElement("p", null, React.createElement(_components.p, null, "Además, tu compañía de Internet y otros espías o hackers pueden ver lo que\nhaces online, controlar tus actividades, y hasta bloquearlas.")), React.createElement("p", null, React.createElement(_components.p, null, "Un VPN ", React.createElement("strong", null, "te protege de todo esto"), ". Cambia tu IP y mete tus datos\nen un túnel encriptado. Así nadie puede leer tu información ni restrarte."))), "\n", React.createElement(_components.p, null, "Bastante más claro, ¿no? ", React.createElement(_components.a, {
    href: "/guias/que-es-vpn/"
  }, "Aquí tienes una explicación más detallada"), ", si te interesa la parte técnica."), "\n", React.createElement(_components.p, null, "Si quieres encontrar ", React.createElement(_components.strong, null, "las mejores opciones calidad/precio"), ", las tienes aquí. Echa un vistazo y elige ahora mismo:"), "\n", React.createElement(Offers), "\n", React.createElement(_components.p, null, "¿Qué te parecen? ¿Sabías que un servicio VPN podía ser ", React.createElement(_components.strong, null, "tan barato"), "?"), "\n", React.createElement(_components.p, null, "No hace falta que sigas leyendo. Lo importante es que elijas uno 👆 y accedas ahora mismo."), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.p, null, "Pero si quieres saber más sobre este tema, no me enrollo. Te dejo los recursos más útiles:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "La ", React.createElement(_components.a, {
    href: "/guias/mejor-vpn-comparativa/"
  }, "comparativa de VPNs"), " con las 10 mejores opciones, actualizada 2022."), "\n", React.createElement(_components.li, null, "O ", React.createElement(_components.a, {
    href: "/"
  }, React.createElement(_components.strong, null, "todos"), " los VPN"), ", rankeados y ordenados para que compares"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/guias/ventajas-de-usar-vpn/"
  }, "Las ventajas de usar uno"), " (y por qué deberías usarlo siempre, para todo)"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
